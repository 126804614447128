<template>
  <div class="sm:container">
    <div class="mb-8 px-5 lg:mb-12 lg:px-0">
      <h2 class="text-lg font-medium text-neutral-900">
        {{ translations.title }}
      </h2>
      <span class="text-xs text-neutral-400">
        {{ translations.description }}
      </span>
    </div>

    <div class="grid gap-2 md:gap-4 lg:grid-cols-3">
      <StageCard
        v-for="stage in preOpeningStages"
        :key="stage.uuid"
        class="cursor-pointer"
        :stage-details="stage"
        :menu-items="stageCardMenuItems"
        @click="openAssetPackage(stage)"
      >
        <template v-slot:dateBadge>
          <span class="text-xs text-neutral-400">
            {{
                turnaroundSlug(stage.turnaround),
            }}
          </span>
        </template>
      </StageCard>
    </div>
  </div>
</template>

<script>
import StageCard from "@/components/operations/preOpening/StageCard";

import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    StageCard,
  },

  beforeRouteEnter(to, _from, next) {
    store.dispatch("preOpeningStages/fetchPreOpeningStages").then(() => next());
  },

  data() {
    return {
      stageCardMenuItems: [
        {
          name: this.$t("components.operations.manageStages.viewAssetPackage"),
          showIf: true,
          callback: () => {}, // Empty callback because the @click on the card already triggers the correct method
        },
      ],

      translations: {
        title: this.$t("components.operations.manageStages.pageTitle"),
        description: this.$t(
          "components.operations.manageStages.pageDescription",
        ),
      },
    };
  },

  computed: {
    ...mapGetters("preOpeningStages", ["preOpeningStages"]),
  },

  methods: {
    turnaroundSlug(totalDays) {
      const weeks = Math.floor(totalDays / 7);
      const days = totalDays % 7;
      let date = [];

      if (weeks) {
        date.push(weeks + " weeks");
      }
      if (days) {
        date.push(days + " days");
      }

      return date.join(", ");
    },

    openAssetPackage(stage) {
      this.$router.push({
        name: "operations-resources-packages-detail",
        params: { packageUuid: stage.assetPackage.uuid },
      });
    },
  },
};
</script>
